<template>
  <span>
    <servicos-v2-visao-detalhada-falhas
      v-if="tipoVisao === 'falhas'"
      ref="visaoDetalhadaFalhas"
      :competencia_de="competencia_de"
      :competencia_ate="competencia_ate"
      :temas_selecionados="temas_selecionados"
      :servicos_selecionados="servicos_selecionados"
      :chavesIndicadores="chavesIndicadores"
      :indicadoresPorTema="indicadoresPorTema"
      :indicadoresLiberados="indicadoresLiberados"
      :authorizedServicos="authorizedServicos"
      :authorizedCompanies="authorizedCompanies"
      :descricaoPorIndicador="descricaoPorIndicador"
      :nomesServicos="nomesServicos"
      :mostrarApenasErros="mostrarApenasErros"
      @updateLoadingDados="$emit('updateLoadingDados', $event)"
      @updateDataTable="getDados()"
    />
    <servicos-v2-visao-detalhada-valores
      v-else-if="tipoVisao === 'valores'"
      ref="visaoDetalhadaValores"
      :competencia_de="competencia_de"
      :competencia_ate="competencia_ate"
      :temas_selecionados="temas_selecionados"
      :servicos_selecionados="servicos_selecionados"
      :chavesIndicadores="chavesIndicadores"
      :indicadoresPorTema="indicadoresPorTema"
      :indicadoresLiberados="indicadoresLiberados"
      :authorizedServicos="authorizedServicos"
      :authorizedCompanies="authorizedCompanies"
      :descricaoPorIndicador="descricaoPorIndicador"
      :nomesServicos="nomesServicos"
      @updateLoadingDados="$emit('updateLoadingDados', $event)"
      @updateDataTable="getDados()"
    />
  </span>
</template>

<script>
  import IndicadoresServicosV2Service from "@/services/IndicadoresServicosV2Service.js";

  export default {
    name: "ServicosV2VisaoDetalhada",
    components: {
      ServicosV2VisaoDetalhadaFalhas: () =>
        import("@/components/validacoes/ServicosV2VisaoDetalhadaFalhas.vue"),
      ServicosV2VisaoDetalhadaValores: () =>
        import("@/components/validacoes/ServicosV2VisaoDetalhadaValores.vue"),
    },
    props: {
      competencia_de: {
        type: String,
        required: true,
      },
      competencia_ate: {
        type: String,
        required: true,
      },
      tipoVisao: {
        type: String,
        required: true,
      },
      temas_selecionados: {
        type: Array,
        required: true,
      },
      categoriasServicos: {
        type: Array,
        required: true,
      },
      authorizedServicosCategories: {
        type: Array,
        required: false,
      },
      servicos_selecionados: {
        type: Array,
        required: true,
      },
      mostrarApenasErros: {
        type: Boolean,
        default: false,
      },
      authorizedCompanies: {
        type: Array,
        required: false,
      },
    },
    data: () => ({
      headers: [],
      items: [],
      dialogDetalhes: false,
      dialogDetalhesSelected: {},
      dialogDetalhesHeader: [],
      dialogDetalhesData: [],
      flagTodosAprovado: false,
      savingCienteValidacao: false,
      searchDataTableAprovacaoIndicador: "",
      chavesIndicadores: [
        "serv_001",
        "serv_002",
        "serv_003",
        "serv_004",
        "serv_005",
      ],
      indicadoresPorTema: {
        Realizados: ["serv_006", "serv_007", "serv_008", "serv_009"],
        Abertos: ["serv_010"],
        Pendentes: ["serv_011", "serv_013", "serv_014"],
        Suspensos: ["serv_012"],
        Compensados: ["serv_015"],
      },
      descricaoPorIndicador: {
        serv_001: "CNPJ da Distribuidora",
        serv_002: "Código IBGE do Município",
        serv_003: "Mês",
        serv_004: "Ano",
        serv_005: "Tipo de serviço",
        serv_006: "Qtd de serviços realizados",
        serv_007: "Prazo médio dos serviços realizados",
        serv_008: "Qtd serviços realizados com descumprimento do prazo",
        serv_009:
          "Prazo médio dos serviços executados com descumprimento do prazo",
        serv_010: "Qtd de serviços solicitados",
        serv_011: "Qtd de serviços ainda não realizados ",
        serv_012: "Qtd de serviços suspensos",
        serv_013:
          "Qtd de serv pendentes de atend e com descumprimento do prazo",
        serv_014:
          "Atraso serv pendentes de atend e com descumprimento do prazo",
        serv_015: "Montante pago em compensações",
      },
    }),
    computed: {
      indicadores() {
        return Object.values(this.indicadoresPorTema).flat();
      },
      indicadoresLiberados() {
        let indicadoresLiberados = [];

        this.temas_selecionados.forEach((tema) => {
          indicadoresLiberados = indicadoresLiberados.concat(
            this.indicadoresPorTema[tema]
          );
        });

        return indicadoresLiberados.toSorted();
      },
      indicadoresBloqueados() {
        return this.indicadores.filter(
          (indicador) => !this.indicadoresLiberados.includes(indicador)
        );
      },
      nomesServicos() {
        let nomesServicos = {};

        this.categoriasServicos.forEach((categoria) => {
          categoria.tipos_servicos.forEach((servico) => {
            nomesServicos[servico.codigo] =
              servico.detalhe_servico.artigo_ren_1000 +
              " - " +
              servico.detalhe_servico.nome_servico;
          });
        });

        return nomesServicos;
      },
      authorizedServicos() {
        let servicos = [];

        this.authorizedServicosCategories.forEach((category) => {
          servicos = servicos.concat(
            category.tipos_servicos.map((tipo_servico) => tipo_servico.codigo)
          );
        });

        return servicos;
      },
    },
    methods: {
      getDados(
        competencia_de = this.competencia_de,
        competencia_ate = this.competencia_ate,
        temas_selecionados = this.temas_selecionados,
        servicos_selecionados = this.servicos_selecionados
      ) {
        switch (this.tipoVisao) {
          case "falhas":
            this.$refs.visaoDetalhadaFalhas.getDados(
              competencia_de,
              competencia_ate,
              temas_selecionados,
              servicos_selecionados
            );
            break;
          case "valores":
            this.$refs.visaoDetalhadaValores.getDados(
              competencia_de,
              competencia_ate,
              temas_selecionados,
              servicos_selecionados
            );
            break;
        }
      },
      downloadArquivoValidacoes() {
        this.$emit("updateDownloadingArquivoValidacoes", true);

        this.$toast.info(
          "Preparando seu arquivo para download, essa etapa pode demorar, por favor, aguarde",
          "",
          {
            position: "topRight",
            timeout: 10000,
          }
        );

        IndicadoresServicosV2Service.downloadArquivoValidacoes(
          this.competencia_de,
          this.competencia_ate,
          this.temas_selecionados.join(","),
          this.servicos_selecionados.join(","),
          { timeout: 60 * 60 * 1000 }
        )
          .then(async (res) => {
            const url = window.URL.createObjectURL(new Blob([res.data]));
            const link = document.createElement("a");
            link.href = url;
            let nomeArquivo = await this.getNomeArquivoValidacoes();
            link.setAttribute("download", `${nomeArquivo}.xlsx`);
            document.body.appendChild(link);
            link.click();
          })
          .catch((err) => {
            this.$toast.error("Erro no download do arquivo.", "", {
              position: "topRight",
              timeout: 5000,
            });
            throw err;
          })
          .finally(() =>
            this.$emit("updateDownloadingArquivoValidacoes", false)
          );
      },
      getNomeArquivoValidacoes() {
        return IndicadoresServicosV2Service.getNomeArquivoValidacoes()
          .then((res) => res.data.nome)
          .catch((err) => {
            this.$toast.error("Erro ao recuperar nome do arquivo.", "", {
              position: "topRight",
            });
            throw err;
          });
      },
    },
  };
</script>

<style></style>
